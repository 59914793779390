import React from "react"
import Toothpaste, { TOOTHPASTE_OPTIONS } from "../sections/toothpaste"
import { extractChevronColumnWidth } from "../helpers/extract-options"
import ResponsiveTitlePlain from "../unitary/responsive-title-plain"
import { PortraitInColumnsDefaultLeft } from "../portrait-children/portraits-in-columns-default-left"
import { TwoByTwo } from "../portrait-children/two-by-two"
import { TRANSLATED_CHEVRON_VERTICAL_ALIGNMENTS } from "../unitary/translated-chevron"

const componentNamesToSymbols = {
  PortraitInColumnsDefaultLeft: PortraitInColumnsDefaultLeft,
  TwoByTwo: TwoByTwo,
}

export const whiteningRenderer = ({
  index,
  data,
  images,
  heightRecorder,
  titleMinHeight,
  bodyMinHeight,
  options,
}) => (
  <Whitening
    key={index}
    listId={index}
    data={data}
    images={images}
    heightRecorder={heightRecorder}
    titleMinHeight={titleMinHeight}
    options={options}
  />
)

class Whitening extends React.Component {
  render() {
    const titleChild = <ResponsiveTitlePlain title={this.props.data.title} />

    const child = React.createElement(
      componentNamesToSymbols[this.props.data.children_component],
      {
        data: this.props.data,
      }
    )

    const options = Object.assign(
      {
        translatedChevronVerticalAlignment:
          TRANSLATED_CHEVRON_VERTICAL_ALIGNMENTS.top,
        toothpasteHasBox: TOOTHPASTE_OPTIONS.toothpasteHasBox.false,
      },
      { ...this.props.options }
    )

    return (
      <div>
        {this.props.listId !== 0 && <hr />}
        <Toothpaste
          heightRecorder={this.props.heightRecorder}
          titleMinHeight={this.props.titleMinHeight}
          titleChild={titleChild}
          chevronColumnWidth={extractChevronColumnWidth(this.props.options)}
          options={options}
        >
          <div>{child}</div>
        </Toothpaste>
      </div>
    )
  }
}
