import React from "react"
import {
  Portrait,
  PORTRAIT_BODY_TEXT_ALIGNMNENTS,
} from "../child-mappable/portrait"
import Columns from "../layout/columns"

export function TwoByTwo(props) {
  return (
    <div>
      <Columns>
        <Portrait
          data={props.data.children[0]}
          options={{
            portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
          }}
        />
        <Portrait
          data={props.data.children[1]}
          options={{
            portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
          }}
        />
      </Columns>
      <Columns>
        <Portrait
          data={props.data.children[2]}
          options={{
            portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
          }}
        />
        <Portrait
          data={props.data.children[3]}
          options={{
            portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
          }}
        />
      </Columns>
    </div>
  )
}
