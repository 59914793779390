// @ts-ignore
import yaml from "../../data/recruiting-for-startups.yaml"
import React from "react"
import { validatePageYamlData } from "../../helpers/prop-types"
import Header from "../../components/sections/header"
import { ChevronIntroduction } from "../../components/sections/chevron-introduction"
import LayoutContainer from "../../components/layout/layout-container"
import { PortraitArrayWithTitle } from "../../components/sections/portrait-array-with-title"
import { PORTRAIT_BODY_TEXT_ALIGNMNENTS } from "../../components/child-mappable/portrait"
import TitleWithUnderline from "../../components/unitary/title-with-underline"
import { SectionBox } from "../../components/sections/section-box"
import ChildMapper from "../../components/child-mapper"
import { whiteningRenderer } from "../../components/child-mappable/whitening"
import Landscape from "../../components/h2nits/landscape"

export default function RecruitingForStartups(props) {
  validatePageYamlData(yaml)

  return (
    <LayoutContainer>
      <Header {...yaml.header} />
      <ChevronIntroduction data={yaml.introduction} />
      <PortraitArrayWithTitle
        data={yaml.outcomes_and_values}
        options={{
          portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
        }}
      />
      <SectionBox>
        <TitleWithUnderline title={yaml.three_steps.title} />
        <ChildMapper
          data={yaml.three_steps.children}
          options={{
            chevronColumnWidth: "is-3",
          }}
          render={whiteningRenderer}
        />
      </SectionBox>
      <SectionBox>
        <Landscape
          data={yaml.learn_more}
          titleStyle="chevron"
          titleClasses={["is-3"]}
        />
      </SectionBox>
    </LayoutContainer>
  )
}
