import React from "react"
import {
  portraitRenderer,
  PORTRAIT_BODY_TEXT_ALIGNMNENTS,
} from "../child-mappable/portrait"
import ChildMapper from "../child-mapper"
import Columns from "../layout/columns"

export function PortraitInColumnsDefaultLeft(props) {
  const options = Object.assign(
    {
      portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
    },
    props.options
  )

  return (
    <Columns>
      <ChildMapper
        data={props.data.children}
        images={props.images}
        options={options}
        render={portraitRenderer}
      />
    </Columns>
  )
}
